@media screen and (max-width: 600px) {
  
  .App{
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  #navbar{
    height: 12vh;
    width: 100vw;
    flex-direction: row-reverse;
    padding: 5% 0 8% 0;
    border-top: 1.5px solid gray;
  }
  #darkmode{
    padding: 2% 2% 0 0;
    top: 0;
  }
  .dark-b-ground{
    background: #25282e;
  }
  .light-b-ground{
    background: white;
  }

  .popouts{
    display: none;
  }

  .section{
    min-height: 50vh;
  }

  .bold{
    font-weight: 500;
  }

  .intro{
    padding-top: 25%;
    text-align: center;
  }

  #intro-hi-hand{
    justify-content: center;
  } 

  .intro h3{
    font-weight: 600;
    font-size: 6.5vw;
  }
  #waving-hand{
    font-size: 6vw;
  }

  .background{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .background p{
    text-align: center;
  }
  #background-header{
    width: 40vw;
  }
  #background-header h3{
    font-size: x-large;
    font-weight:bold;
  }
  .background h3{
    font-weight: 400;
    font-size: 6vw;
    margin-bottom: 25%;
  }
  .background p{
    font-weight: 300;
    font-size: 4.5vw;
  }



  .skills{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #skills-header{
    width: 40vw;
  }
  #skills-header h3{
    font-size: x-large;
    font-weight:bold;
  }
  .skills h3{
    font-weight: 400;
    font-size: 6vw;
    margin-bottom: 15%;
  }
  #skills-content {
    flex-direction: column;
    justify-content: space-between;
  }
  #frontend{
    margin: 0 0 18% 0;
  }
  #backend{
    margin: 0 0 18% 0;
  }
  #dbtools{
    margin: 0;
  }
  #skills-content h5{
    font-size: 5vw;
    font-weight: 400;
    margin-bottom: 5%;
  }
  #skills-content p{
    font-size: 4vw;
  }





  .projects{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10% 8% 20% 8%;
  }
  .project-wrapper{
    margin: 1.5vh 0;
    width: 75vw;
  }
  #projects-content{
    flex-direction: column;
  }
  .project-img-wrapper{
    width: 70vw;
  }
  #projects-header{
    width: auto;
  }
  #projects-header h3{
    font-size: x-large;
    font-weight:bold;
  }
  .projects h3{
    font-weight: 400;
    font-size: 6vw;
    margin-bottom: 15%;
  }
  #projects-content h5{
    font-size: 5vw;
    margin: 10px 0 15px 0;

  }
  #projects-content p{
    font-size: 4vw;
    margin: 0;
  }
  .chosen{
    display: none;
  }
  .blur{
    opacity: 1;
  }

}