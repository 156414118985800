body{
  font-family: 'Raleway', sans-serif;
  background-color: whitesmoke;
  scroll-snap-type: y mandatory;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 10% 8% 10% 8%;
  min-height: 85vh;
  height: fit-content;
  cursor: default;
}

a {
  text-decoration: none;
  color: black;
}
a:hover{
  color: black;
}

.bold {
  font-weight: 400;
}

/*********** NAVBAR *************/
.invert {
  filter: invert(1)
}

#navbar {
  height: 50vh;
  min-width: 50px;
  width: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0%;
}
#navbar a{
  text-decoration: none;
}
#resume{
  height: 32px;
  width: 29px;
  font-size: 2.2em;
  margin-left: 2px;
  border-radius: 8%;
  color: rgba(29, 29, 29, 0.95);
}
#resume:hover{
  cursor: pointer;
  filter: none;
  color: rgb(86, 138, 250);
} 
#github{
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: rgba(29, 29, 29, 0.95);
}
#github:hover{
  cursor: pointer;
  filter: none;
  color: rgb(86, 138, 250);
} 
#email{
  height: 24px;
  width: 32px;
  border-radius: 8%;
  color: rgba(29, 29, 29, 0.95);
}
#email:hover{
  cursor: pointer;
  filter: none;
  color: rgb(86, 138, 250);
} 
#linkedin{
  height: 32px;
  width: 32px;
  font-size: 2.2em;
  border-radius: 8%;
  color: rgba(29, 29, 29, 0.95);
}
#linkedin:hover{
  cursor: pointer;
  filter: none;
  color: rgb(86, 138, 250);
} 
#blog{
  height: 32px;
  width: 32px;
  border-radius: 8%;
  color: rgba(29, 29, 29, 0.95);
}
#blog:hover{
  cursor: pointer;
  filter: none;
  color: rgb(86, 138, 250);
} 
#darkmode{
  position: fixed;
  right: 0;
  padding: .9% 1% 0 0;
  color: rgba(29, 29, 29, 0.95);
}
#darkmode:hover{
  cursor: pointer;
  filter: invert(1);
} 
#darkmode:active{
  border: none;
} 

.popouts{
  height: 50vh;
  min-width: 50px;
  width: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0%;
  z-index: -1;
  padding-top: 14px;
}
.popouts p{
  font-weight: 500;
  font-size:large;
}


/************ INTRO ***************/
.intro {
  padding: 10% 20% 10% 25%;
}
.intro h3{
  font-weight: 300;
  font-size: 4vw;
  margin-right: 8px;
}
#intro-hi-hand{
  display: flex;
  align-items: center;
}
#waving-hand{
  font-size: 3.6vw;
}


/************ BACKGROUND ***********/
.background {
  flex-direction: row;
  justify-content: space-evenly;
}
#background-header{
  width: 25vw;
}
#background-content{
  width: 60vw;
  margin-left: 4%;
}
.background h3{
  text-align: center;
  margin-top: 0;
  font-size: 4vw;
  font-weight: 400;
  color: rgb(86, 138, 250);
}
.background p{
  margin-top: 1%;
  font-size: 2vw;
  font-weight: 200;
}

/***************** SKILLS ***************/
.skills {
  flex-direction: row;
  justify-content: space-evenly;
}
#skills-header{
  width: 25vw;
  margin: 6px;
}
#skills-content{
  display: flex;
  justify-content: center;
  width: 60vw;
  margin: 6px;
}
#skills-content h5{
  margin-top: 0;
  font-size: 2.5vw;
  font-weight: 300;
}
#skills-content p{
  font-size: 2vw;
  font-weight: 200;
  margin: 0;
}
#frontend{
  margin: 0 3vw 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#backend{
  margin: 0 3vw 0 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#dbtools{
  margin: 0 0 0 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.skills h3{
  padding-right: 5%;
  text-align: center;
  margin-top: 0;
  font-size: 4vw;
  font-weight: 400;
  color: rgb(86, 138, 250);
}


/************** PROJECTS *************/
.projects {
  flex-direction: row;
  height: fit-content;
  padding-right: 0;
  padding-bottom: 1%;
  cursor: default;
}
.projects h3{
  padding-right: 5%;
  text-align: center;
  margin-top: 0;
  font-size: 4vw;
  font-weight: 400;
  color: rgb(86, 138, 250);
}
#projects-content h5:hover{
  cursor: pointer;
  font-weight: 500;
  /* color: rgb(101, 146, 244); */
  /* text-shadow: 2px 2px 3px black; */
}
#projects-header{
  width: 15vw;
  margin: 6px;
}
#projects-content{
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 78%;
  margin: 6px;
}
#top-proj-content{
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  height: 100%;
}
#btm-proj-content{
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  height: 100%;
}
#projects-content h5{
  margin: 0;
  text-align: center;
  font-size: 2.5vw;
  font-weight: 300;
  color: rgb(86, 138, 250);
}
.description{
  text-align: center;
  font-weight: 200;
  font-size: 1.5vw;
}
.project-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3%;
  min-height: 40%;
  height: 45vh;

  padding: 3%;
}
.project-img-wrapper{
  width: 34vw;
  align-items: center;
  display: flex;
  overflow: auto;
  border-radius: 10px;
  /* border: 2px solid lightgray; */
}
.project-img{
  max-height: 8em;
  margin: 0 3px 0 3px;
}
.project-img:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer; 
}
.chosen{
  width: 30vw;
  height: 26vh;
  position: absolute;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  /* padding-right: 6vw; */
}
.blur{
  opacity: 0.3;
}
.stack{
  font-size: smaller;
  margin: 0;
  text-align: center;
  font-weight: 500;
}



.quizcard {
  align-items: flex-end;
}
.quizcard p{
  text-align: right;
}


.fcebook {
  align-items: flex-start;
}
.fcebook p{
  text-align: left;
}

.cocktail {
  align-items: flex-end;
}
.cocktail p{
  text-align: right;
}

.mixhub {
  align-items: flex-start;
}
.mixhub p{
  text-align: left;
}